import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Commun/Banner"
import Section from "../components/elements/Section"
import globalVariables from "../components/globalVariables"
import Social from "../components/Commun/LayoutContent/Social"
import ContactForm from "../components/Commun/Contact/ContactForm"
import Address from "../components/Commun/LayoutContent/Address"
import BasicFit from "../components/Commun/LayoutContent/BasicFit"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    padding: 0 2rem;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 0 4rem;
  }
`

const Sidebar = styled.div`
  order: 2;
  margin-top: 2rem;

  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 1 22%;
    order: 1;
    margin-top: 0;
  }
`

const Content = styled.div`
  flex: 1 1 78%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  order: 1;
  @media (min-width: ${globalVariables.minTablet}) {
    padding-left: 2rem;
    margin-top: 0;
    order: 2;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding-left: 4rem;
  }
  @media (max-width: ${globalVariables.maxWidth}) {
    order: 1;
  }
`
const WrapperContent = styled.div`
  background-color: white;
  padding-top: 2rem;
  .titre {
    text-align: left;
  }
`

const ContactPage = ({ data }) => {
  const prismicData = getPrismicData(data)
  console.log("prismicData ", prismicData)
  return (
    <Layout kevinFooter={data.kevinFooter.childImageSharp.fixed}>
      <SEO title="Contactez-moi | Kevin Jehanno | Coach Sportif" />
      <Banner
        title={prismicData.banner.titre}
        imageBanner={prismicData.banner.image}
        imageHeight="350px"
        imageMobileHeight="350px"
        isfirstBlock
        marginBottom="0"
        objectPosition="center 30% !important"
      />
      <Section color="greyLighter">
        <Wrapper>
          <Sidebar>
            <Address data={prismicData.cordonnees} />
            <BasicFit logo={data.logoBasicFit.childImageSharp.fixed} />
            <Social />
          </Sidebar>
          <Content>
            <WrapperContent>
              <ContactForm />
            </WrapperContent>
          </Content>
        </Wrapper>
      </Section>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    prismicContactBodyBanniere {
      primary {
        titre {
          text
        }
        image {
          fluid(maxWidth: 3000) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
    prismicContactBodyCoordonnees {
      primary {
        adresse {
          text
        }
        horaires {
          text
        }
      }
    }

    kevinFooter: file(relativePath: { eq: "Kevin-footer.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    logoBasicFit: file(relativePath: { eq: "basic-fit-logo.png" }) {
      childImageSharp {
        fixed(quality: 100, width: 50, height: 50) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`
function getPrismicData(data) {
  return {
    banner: {
      titre: data.prismicContactBodyBanniere.primary.titre.text,
      image:
        data.prismicContactBodyBanniere.primary.image.fluid,
    },
    cordonnees: {
      adresse: data.prismicContactBodyCoordonnees.primary.adresse.text,
      horaires: data.prismicContactBodyCoordonnees.primary.horaires.text,
    },
  }
}
