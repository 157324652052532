import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../../globalVariables"

const Wrapper = styled.div`
  margin-left: 0;
  background-color: white;
  border-radius: ${globalVariables.borderRadius};
  padding: 2rem;
  height: fit-content;
  margin-top: 2rem;
  a span.btn-text {
    font-size: 16px;
  }
`

const Title = styled.p`
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.headerColor};
  margin-bottom: 1rem;
`

const BasicFit = ({ logo }) => {
  return (
    <Wrapper>
      <Title>Coach Basic-Fit</Title>
      <Img fixed={logo} />
    </Wrapper>
  )
}

export default BasicFit
