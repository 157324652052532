import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"
import { MapMarkerAlt } from "styled-icons/fa-solid/MapMarkerAlt"
import { Clock } from "styled-icons/fa-regular/Clock"

const Wrapper = styled.div`
  margin-left: 0;
  background-color: white;
  border-radius: ${globalVariables.borderRadius};
  padding: 2rem;
  height: fit-content;
  a span.btn-text {
    font-size: 16px;
  }
`

const Title = styled.p`
  font-family: Rajdhani;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: bold;
  color: ${props => props.theme.headerColor};
  margin-bottom: 1rem;
`
const List = styled.ul`
  margin-left: 0;
`

const Item = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  span {
    color: ${props => props.theme.bodyColor};
    padding: 0.5rem 0;
    font-size: 14px;
  }
`

const ClockStyled = styled(Clock)`
  color: ${props => props.theme.secondary};
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  @media (min-width: ${globalVariables.bigDesktop}) {
    width: 20px;
    height: 20px;
  }
`
const MapMarkerAltStyled = styled(MapMarkerAlt)`
  color: ${props => props.theme.secondary};
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`

const Address = ({ data }) => {
  return (
    <Wrapper>
      <Title>À Nantes</Title>
      <List>
        <Item>
          <MapMarkerAltStyled />
          <span>{data.adresse}</span>
        </Item>
        <Item>
          <ClockStyled />
          <span>{data.horaires}</span>
        </Item>
      </List>
    </Wrapper>
  )
}

export default Address
